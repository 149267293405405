* {
  box-sizing: border-box;
}    

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p { 
  margin: 0;
}

.mb-3 {
  margin-bottom: 3% !important;
}

.mb-3 > p {
  margin-bottom: 2rem !important;
}

.navbar-brand {
  color: #fff !important;
}

.card {
  border: 0;
}

.card-body {
  border-radius: 4px;
}

.card-body:hover {
  box-shadow: 0.4rem 0.4rem 0 black;
  transform: translate(-0.4rem, -0.4rem);
  border-radius: 4px;
}